import React from 'react';
import { connect } from 'react-redux'
import { setSearchParams, removeSearchParams } from "../../api/cars/actions";
import { fetchAllSearchParams } from '../../api/searchParams/actions';
import { getGasTypeValue } from '../../utils';

class AutoComplete extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text: this.props.type == '',
            selectedItem: '',
            searchItems: this.props.searchItems,
        }
        this.suggestionSelected = this.suggestionSelected.bind(this);
    }

    componentDidMount() {
        if (this.props.searchItems && this.props.searchItems.length === 1) {
            if (this.props.searchItems[0] instanceof Array) {
                this.setState({
                    text: this.props.searchItems[0][1],
                    selectedItem: this.props.searchItems[0][0],
                })
            }
            else {
                this.setState({
                    text: this.props.searchItems[0],
                    selectedItem: this.props.searchItems[0],
                })
            }
        }
        addSelect2Library();
        let that = this;
        $('.input-wrapper.'+this.props.type).find('select').on('select2:select', function (e) {
            that.suggestionSelected(e, "select2");
        });
        if (this.props.type === "make" && this.props.makeVal !== "") {
            $('.input-wrapper.make select').val(this.props.makeVal).trigger('change');
            this.setState({
                text: this.props.makeVal,
                selectedItem: this.props.makeVal,
            })
        }
    }


    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(this.props.searchItems) != JSON.stringify(prevProps.searchItems)) {
            this.setState({
                searchItems: this.props.searchItems,
            })
            addSelect2Library();
        }

        if (this.props.type == "make" && this.props.makeVal !== prevProps.makeVal) {
            $('.input-wrapper.make select').val(this.props.makeVal).trigger('change');
            this.setState({
                text: this.props.makeVal,
                selectedItem: this.props.makeVal,
            })
        }

        if (this.props.type == "model" && this.props.modelVal !== prevProps.modelVal) {
            $('.input-wrapper.model select').val(this.props.modelVal).trigger('change');
            this.setState({
                text: this.props.modelVal,
                selectedItem: this.props.modelVal,
            })
        }

        if (this.props.type === "model" && this.props.searchItems !== prevProps.searchItems && this.props.cars.searchParams.make === "") {
            this.setState({
                searchItems: this.props.searchItems,
                text: "",
                selectedItem: "",
            })
        }
        if (this.props.searchItems !== prevProps.searchItems && this.props.type === "cc" && this.props.searchItems.length === 1) {
            this.setState({
                text: this.props.searchItems[0],
                selectedItem: this.props.searchItems[0],
            })
            this.props.setSearchPath(this.props.searchItems[0]);
        }
    }

    suggestionSelected(e, from) {
        let value = "";
        if (from == "select2") {
            value = e.params.data.id;
        } else if (from === "select") {
            value = e.target.value;
        }
        if (this.props.type === "gear") {
            Promise.resolve(this.props.store.dispatch(setSearchParams({ key: this.props.type, value: value === "Sjálfskiptur" ? 'auto' : 'man' })))
                .then(
                    this.setState({
                        text: value,
                        selectedItem: value,
                    })
                )
        } else if (this.props.type === "cc") {
            // let modVal = ""
            // if (value === "Fólksbílar") {
            //     modVal = "car"
            // } else if (value === "Jeppar") {
            //     modVal = "jeep"
            // } else if (value === "Rafbílar") {
            //     modVal = "spec"
            // }
            
            Promise.resolve(this.props.store.dispatch(setSearchParams({ key: this.props.type, value: value })))
            this.props.setSearchPath(value);
                this.setState({
                    text: value,
                    selectedItem: value,
                })
        } else if (this.props.type == "make") {
            Promise.resolve(this.props.store.dispatch(setSearchParams({ key: this.props.type, value: value })))
                .then(this.props.store.dispatch(setSearchParams({ key: 'model', value: '' })))
                .then(
                    this.setState({
                        text: value,
                        selectedItem: value,
                    })
                )
        } else if (this.props.type === "gt") {
            Promise.resolve(this.props.store.dispatch(setSearchParams({ key: this.props.type, value: getGasTypeValue(value) })))
            this.props.setSearchPath(value);
                this.setState({
                    text: value,
                    selectedItem: value,
                })
        } else {
            Promise.resolve(this.props.store.dispatch(setSearchParams({ key: this.props.type, value: value })))
                .then(
                    this.setState({
                        text: value,
                        selectedItem: value,
                    })
                )
        }
        this.props.updateSearchLists(this.props.type, value);
        if (this.props.fromHomepage != true) {
            this.props.filtersHaveChanged(true);
        }
    }

    renderSuggestions() {
        let items = this.state.searchItems;
        if (items) {
            if (items.length === 0) {
                return null;
            }
            if (items.length === 1) {
                return ([<option value="" key={0}>{this.getCorrectPlaceholder()}</option>, <option key={1}>{items[0]}</option>])
            }
            return (
                [<option value="" key={-1}>{this.getCorrectPlaceholder()}</option>,
            items.map((item, key) => {
                if(item instanceof Array) {
                    return <option value={item[0]} key={key}>{gettext(item[1])}</option>
                } else {
                    return <option value={item} key={key}>{gettext(item)}</option>
                }
            })]
            )
        }
    }

    getCorrectPlaceholder() {
        if (this.props.type === "make") {
            return gettext('Allir framleiðendur')
        } else if (this.props.type === "model") {
            return gettext('Allar gerðir')
        } else {
            return gettext('Ekkert valið')
        }
    }


    render() {
        const defaultValue = this.state.selectedItem;
        return (
            <div className="autocomplete-text">
                <div className={"input-wrapper d-lg-none " + this.props.type} key="1">
                    <select defaultValue={defaultValue} onChange={(e) => this.suggestionSelected(e, "select")}>
                        {this.renderSuggestions()}
                    </select>
                </div>
                <div className={"input-wrapper form-group d-none d-lg-block " + this.props.type} key="2">
                    <select defaultValue={defaultValue}>
                        {this.renderSuggestions()}
                    </select>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {

    var f = {
        cars: {},
    }
    f.cars = (state.cars === undefined) ? {} : state.cars;
    return f;
}

export default connect(mapStateToProps)(AutoComplete);