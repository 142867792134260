import React from 'react';
import { currencyFormatter } from '../../utils';
import Spinner from '../spinner';


class MyInformation extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    getExtras(extras) {
        let allExtras = extras.map((extra, i) => {
            if (i < 3) {
                return (<span>{extra} </span>)
            }
        })
        return allExtras;
    }

    getCorrectPriceAndContractType(location) {
        if (location.contractType === "winter") {
            return (
                <>
                    <div className="font-weight-normal d-inline">{gettext('Mánaðarleiga')}</div>
                    <span className="font-weight-normal d-block"><h5 className="font-weight-normal d-inline">{currencyFormatter(location.car.price_winter)}</h5><span className="d-inline"> {gettext('kr./mán.')}</span></span>
                </>
            )
        } else if (location.contractType === "12months") {
            return (
                <>
                    <div className="font-weight-normal d-inline">12 {gettext('mánuðir')}</div>
                    <span className="font-weight-normal d-block"><h5 className="font-weight-normal d-inline">{currencyFormatter(location.car.price_12m)}</h5><span className="d-inline"> {gettext('kr./mán.')}</span></span>
                </>
            )
        } else if (location.contractType === "24months") {
            return (
                <>
                    <div className="font-weight-normal d-inline">24 {gettext('mánuðir')}</div>
                    <span className="font-weight-normal d-block"><h5 className="font-weight-normal d-inline">{currencyFormatter(location.car.price_24m)}</h5><span className="d-inline"> {gettext('kr./mán.')}</span></span>
                </>
            )
        } else if (location.contractType === "36months") {
            return (
                <>
                    <div className="font-weight-normal d-inline">36 {gettext('mánuðir')}</div>
                    <span className="font-weight-normal d-block"><h5 className="font-weight-normal d-inline">{currencyFormatter(location.car.price_36m)}</h5><span className="d-inline"> {gettext('kr./mán.')}</span></span>
                </>
            )
        }
    }

    getCar(car) {
        return (
            <div className="card-wrapper p-0" id={car.fastanumer}>
                <div className="card card-car">
                    <div className="card-body">
                        <div className="card-information">
                            <div className="card-title">
                                <h5>{car.car_type_verbose}</h5>
                            </div>
                            <h6>{car.bags} {car.doors} {car.gear}</h6>
                            <h6 className="font-weight-bold">Mánaðarleiga {this.getCorrectPriceAndContractType(this.props.location)}</h6>
                            <img className="card-img img-fluid" src={car.card_image}></img>
                        </div>
                        <div className="red-bottom">

                        </div>
                    </div>
                </div>
            </div>
        )
    }

    getContent(member) {
        if(member) {
            let memberObj = member
            if (member[0]) {
                memberObj = member[0]
            }
            return ( 
                <form className="row" action="{% pageurl page %}" method="POST">
                <div className="col-4 form-group">
                    <label htmlFor="ssn" className="col-form-label">{gettext('Kennitala')}</label>
                    <input type="number" name="ssn" maxLength="255" required="" id="ssn" value={memberObj.kennitala} disabled />
                </div>
                <div className="col-8 form-group">
                    <label htmlFor="name" className="col-form-label">{gettext('Nafn')}</label>
                    <input type="text" name="name" maxLength="255" required="" id="name" value={memberObj.nafn} disabled />
                </div>
                <div className="col-8 form-group">
                    <label htmlFor="address" className="col-form-label">{gettext('Heimilisfang')}</label>
                    <input type="text" name="address" maxLength="255" required="" id="address" value={memberObj.gata} disabled />
                </div>
                <div className="col-4 form-group">
                    <label htmlFor="postal-code" className="col-form-label">{gettext('Staður')}</label>
                    <input type="text" name="postal-code" maxLength="255" required="" id="postal-code" value={memberObj.postnumer + ", " + memberObj.stadur} disabled />
                </div>
                <div className="col-12 pt-5 form-group">
                    <label htmlFor="phone-number" className="col-form-label">{gettext('Símanúmer')}</label>
                    <input type="text" name="phone-number" maxLength="255" required="" id="phone-number" value={memberObj.simi ? memberObj.simi : this.props.phoneno} disabled/>
                </div>
                <div className="col-12 form-group">
                    <label htmlFor="email" className="col-form-label">{gettext('Netfang')}</label>
                    <input type="email" name="email" maxLength="255" required="" id="email" value={memberObj.email} disabled/>
                </div>
                {/* <div className="col-12 pb-5 form-group">
                    <label htmlFor="confirmed-email" className="col-form-label">Staðfesta netfang</label>
                    <input type="email" name="confirmed-email" maxLength="255" required="" id="confirmed-email" value={this.state.confirmedEmail} />
                </div> */}
                {/* <div className="col-12 form-group">
                    <input type="checkbox" name="receive-information" required="" id="receive-information" value={this.state.confirmedEmail} />
                    <label htmlFor="receive-information" className="col-form-label">Já, ég vil gjarnan fá upplýsingar um tilboð, afslætti og fréttir frá AVIS</label>
                </div>
                <div className="col-12 form-group">
                    <input type="checkbox" name="avis-offers" required="" id="avis-offers" value={this.state.confirmedEmail} />
                    <label htmlFor="avis-offers" className="col-form-label">Ég vil fá tilboð frá samstarfsaðilum AVIS</label>
                </div> */}
            </form>
            )
        } else {
            return <div>Engar upplýsingar</div>
        }
    }

    render() {
        let member = this.props.member;
        return (
            <div className="my-pages container-fluid">
                <div className="container">
                    <div className="col-12 my-information">
                        <div className="row">
                            <h2 className="col-12 pl-0 pr-0">{gettext('Persónulegar upplýsingar')}</h2>
                            <div className="col-12 col-lg-6 pl-0 pt-4 info-section">
                                {this.getContent(member)}
                            </div>
                            {this.props.car ?
                                <>
                                    <div className="col-0 col-lg-2"></div>
                                    <div className="col-12 col-lg-4">
                                        {this.getCar(this.props.car)}
                                    </div>
                                </> : null}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

MyInformation.defaultProps = {
}


export default MyInformation;