import React from 'react'
import ReactDOM from 'react-dom/client';
import LoggedInMenu from './loggedInMenu'
import { store } from '../../store' 
var $ = require( "jquery" );

$.fn.loggedInMenu = function () {
  return this.each(function () {
    var options = $(this).data();
    options.store = store;
    const root = ReactDOM.createRoot(this);
    root.render(React.createElement(LoggedInMenu, options));
  });
};

/**
 * Initialize Data Attribute
 */

$(document).ready(function () {
  $('[data-loggedin-menu]').loggedInMenu();
});