import React from 'react';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import { currencyFormatter, getCarGroupName, getCarPath, getColor, getColorIS, getPath } from '../../utils';
import { selectCar, savePathname } from '../../api/cars/actions'
import { fetchUpsellCars } from '../../api/upsell/actions'
import { SVGVatFree } from '../../utils';

let trans = [
    gettext("Árgerð"),
    gettext("Litur"),
    gettext("Verð frá"),
    gettext("kr./mán."),
]

class CarSlider extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            pathname: window.location.pathname,
        }
    }

    componentDidMount() {
        $('.slick-list').addClass('no-left-padding');
    }

    onCarChoose(car) {
        this.props.store.dispatch(selectCar(car));
        this.props.store.dispatch(fetchUpsellCars(car.car_number, true));
        this.props.onChooseCar(true);
    }


    toggleExtras() {
        this.setState({
            showAllExtras: !this.state.showAllExtras,
        })
    }

    getExtras(extras) {
        if (extras) {
            let allExtras = extras.map((extra, i) => {
                if (this.state.showAllExtras) {
                    return (
                        <div className="col-4 extra">
                            {gettext(extra)}
                        </div>
                    )
                } else {
                    if (i < 6) {
                        return (
                            <div className="col-4 extra">
                                {gettext(extra)}
                            </div>
                        )
                    }
                }
            })
            return allExtras;
        }
    }

    getGears(gear) {
        if (gear === 'man'){
            return gettext('Beinskiptur')
        } else {
            return gettext('Sjálfskiptur')
        }
    }

    getCar(car, i) {
        let carGroupName = getCarGroupName(car.group_code);
        let pathname = getPath(carGroupName);
        return (
            <div className="card-wrapper" key={i}>
                <Link className="card card-car" key={car.car_number} to={{ pathname: window.location.pathname === "/" || window.location.pathname === `/${this.props.lang}/` ? pathname : window.location.pathname, hash: `${car.car_number}`, specialCars: this.props.specialCars, car }} onClick={this.onCarChoose.bind(this, car)}>
                    <div className="card-body">
                        <div className="card-information">
                            <div className="card-title">
                                <div className="group">{car.group_category_name}{car.show_vat_free_logo && " - verð án vsk út árið 2023"}</div>
                                <div>{car.car_type_verbose}</div>
                            </div>
                            <div className="precise-info row">
                                <div className="col-3">
                                    <div className="title">{gettext('Árgerð')}</div>
                                    <div>{`20` + car.model_year}</div>
                                </div>
                                <div className="col-4"> 
                                    <div className="title">{gettext('Skipting')}</div>
                                    <div>{this.getGears(car.gear)}</div>
                                </div>
                                <div className="col-5">
                                    <div className="title">{gettext('Verð frá')}</div>
                                    <div>{currencyFormatter(car.price_min)} {gettext('kr./mán.')}</div>
                                </div>
                            </div>
                            <img className="card-img img-fluid" src={car.card_image}></img>
                        </div>
                        <div className="card-bottom">

                        </div>
                    </div>
                </Link>
            </div>
        )
    }

    getAllCars(cars) {
        if (cars) {
            let allCars = [].concat(cars.results)
                .sort((a, b) => parseFloat(a.price_min) - parseFloat(b.price_min)).map((car, i) =>
                    this.getCar(car, i + 1)
                )
            return allCars;
        }

    }


    render() {
        let cars = this.props.cars;
        var settings = {
            dots: false,
            infinite: false,
            rows: 1,
            speed: 300,
            slidesToShow: 3,
            slidesToScroll: 1,
            centerMode: false,
            focusOnSelect: true,
            variableWidth: true,
            mobileFirst: true,
            prevArrow: 
                <button>
                    <svg className="icon-prev" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 16.8 12.8">
                        <path fill="#D2072F" d="M4.8 0l.8.6-4 5.3h15.2v1H1.6l4 5.3-.8.6L0 6.4z"/>
                    </svg>
                </button>,
            nextArrow: 
                <button>
                    <svg className="icon-next" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 16.8 12.8">
                        <path fill="#D2072F" d="M12 0l-.8.6 4 5.3H0v1h15.1l-3.9 5.3.8.6 4.8-6.4z"/>
                    </svg>
                </button>,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        swipe: true,
                    }
                }
            ],
            beforeChange(lastSlide, nextSlide) {
                if (nextSlide === 0) {
                    $('.slick-list').addClass('no-left-padding');
                } else {
                    $('.slick-list').removeClass('no-left-padding');
                }
            }

        };
        return (
            <div className={this.props.carChosen ? "single-car" : ""} >
                <Slider {...settings}>
                    {cars.isFetching ? <div className="spinner"></div> : this.getAllCars(cars)}
                </Slider>
            </ div>

        )
    }
}


CarSlider.defaultProps = {
}


export default CarSlider;